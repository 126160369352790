<template>
  <div class="snackbar" :style="{ backgroundColor:backgroundColor }">
    <online></online>
    <span class="item">{{message}}</span>
  </div>
</template>

<script>
export default {
  props: ["message", "backgroundColor"],
  created() {
    console.log("entered snackbar");
  },
  components: {
    online: () => import("@/components/SvgImages/Online.vue")
  }
};
</script>


<style lang="scss">
@import "@/sass/_variables.scss";
@import "./Snackbar.scss"
</style>
